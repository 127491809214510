.additional-info{
  padding:6px;
  font-size:13px;

  h2{
    font-size:16px;
    font-weight:bold;
    margin-top:10px;
    margin-bottom:7px;
  }

  h3{
    font-size:14px;
    font-weight:bold;
    margin:5px 0;
  }

  a{
    color:#9baec8;
    text-decoration: none;
  }

  ul.kakomi {
    background-color: #333344;
    padding: 5px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    line-height: 130%;
  }

  li{
    padding-left:5px;
    ul {
      padding-left:5px; 
    }
  }
}

.display-name__html {
    font-weight: bold;
}

.landing-page .header .links .brand img {
    height: 70px;
}

.landing-page .header .hero .heading {
    padding-bottom: 180px;
}

.landing-page ul {
  font-size: 16px;
  line-height: 30px;
  color: #606984;
}

.logo-container h1 img {
    height: 90px;
}

.compose-panel .compose-form {
  flex: 0;
}

